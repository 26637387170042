import type { IconProps } from '../../types/Icon';

type ChevronProps = {
  opacity?: string;
  stroke?: string;
  strokeWidth?: string;
  width?: string;
  height?: string;
} & IconProps;

const Chevron = ({
  opacity = '0.7',
  strokeWidth = '2',
  stroke = '#FFF',
  className,
  height,
  width,
}: ChevronProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 15 31"
    className={className}
    height={height}
    width={width}
  >
    <path
      fill="none"
      fillRule="evenodd"
      stroke={stroke}
      strokeLinecap="round"
      strokeWidth={strokeWidth}
      d="M1.6 1.73l12 14-12 14"
      opacity={opacity}
    />
  </svg>
);

export default Chevron;
