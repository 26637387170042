import styled, { css } from 'styled-components';
import { baseTheme } from '../../styles/app/theme';
import { perks } from '../../utils/constants/home';
import {
  SwiperClasses,
  swiperContainerStyles,
  swiperSlideStyles,
  swiperWrapperStyles,
} from '../../utils/constants/swiper';
import breakpoints from '../../utils/helpers/responsive';
import type { SupportedLanguage } from '@dx-ui/framework-i18n';

const OuterWrapper = styled.section`
  padding: 1rem 0;
  .${SwiperClasses.Container} {
    ${swiperContainerStyles};
    padding: 2rem 0;
  }
  .${SwiperClasses.Wrapper} {
    ${swiperWrapperStyles};
  }
  .${SwiperClasses.Slide} {
    ${swiperSlideStyles};
  }
`;

const Li = styled.li`
  pointer-events: none;
  && {
    width: 46.5%;
  }
`;

const sharedLineStyles = css`
  content: '';
  display: block;
  position: absolute;
  top: 40px;
  height: 1px;
  opacity: 0.7;
  background-color: #d1d1d1;
  z-index: 1;
  transition: none;
  width: calc(50% - 65px);
  ${baseTheme.hilton.mqs.medium`
    top: 55px;
    width: calc(50% - 80px);
  `};
  ${baseTheme.hilton.mqs.xtraLarge`
    top: 55px;
  `};
`;

const sharedInactiveLineStyles = css`
  ${baseTheme.hilton.mqs.small`
    width: calc(50% - 50px);
  `};
  ${baseTheme.hilton.mqs.xtraLarge`
    width: calc(50% - 65px);
  `};
`;

const leftLine = css<{ language?: SupportedLanguage }>`
  &:before {
    ${sharedLineStyles};
    ${({ language }) => (baseTheme.isRTL(language) ? 'right: 0' : 'left: 0')};
  }
`;

const rightLine = css<{ language?: SupportedLanguage }>`
  &:after {
    ${sharedLineStyles};
    ${({ language }) => (baseTheme.isRTL(language) ? 'left: 0' : 'right: 0')};
  }
`;

const inActiveLeftLine = css<{ language?: SupportedLanguage }>`
  &:before {
    ${sharedLineStyles};
    ${sharedInactiveLineStyles};
    ${({ language }) => (baseTheme.isRTL(language) ? 'right: 0' : 'left: 0')};
  }
`;

const inActiveRightLine = css<{ language?: SupportedLanguage }>`
  &:after {
    ${sharedLineStyles};
    ${sharedInactiveLineStyles};
    ${({ language }) => (baseTheme.isRTL(language) ? 'left: 0' : 'right: 0')};
    ${baseTheme.hilton.mqs.medium`
      width: calc(50% - 65px);
    `};
  }
`;

const slideItemStyles = css`
  ${inActiveRightLine};
  ${baseTheme.hilton.mqs.mediumAndDown`
    ${inActiveLeftLine};
  `};
  ${baseTheme.hilton.mqs.largeAndUp`
    &:before {
      ${({ language }) =>
        baseTheme.isRTL(language as SupportedLanguage) ? 'right: -7.5%' : 'left: -7.5%'};
    }
    &:after {
      ${({ language }) =>
        baseTheme.isRTL(language as SupportedLanguage) ? 'left: 7.5%' : 'right: 7.5%'};
    }
  `};
`;

const slideItemActiveStyles = css`
  ${leftLine};
  ${rightLine};
  padding-left: 5%;
  padding-right: 5%;
  ${baseTheme.hilton.mqs.largeAndUp`
    padding-left: 0;
    padding-right: 0;
    &:before {
      ${({ language }) =>
        baseTheme.isRTL(language as SupportedLanguage) ? 'right: -7.5%' : 'left: -7.5%'};
    }
    &:after {
      ${({ language }) =>
        baseTheme.isRTL(language as SupportedLanguage) ? 'left: -7.5%' : 'right: -7.5%'};
    }
  `};
`;

const slideContentActiveStyles = css`
  background-color: white;
  min-height: 220px;
  ${baseTheme.hilton.mqs.smallAndDown`
    opacity: 1;
  `};
  ${baseTheme.hilton.mqs.medium`
    min-height: 340px;
  `};
  ${baseTheme.hilton.mqs.xtraLargeAndUp`
    min-height: 300px;
  `};
  ${baseTheme.hilton.mqs.xtraLarge`
    min-height: 380px;
  `};
  &:before {
    border-width: 30px;
    top: -28px;
  }
`;

const slideItemLastStyles = css`
  ${inActiveLeftLine};
  ${baseTheme.hilton.mqs.largeAndUp`
    &:before {
      ${({ language }) =>
        baseTheme.isRTL(language as SupportedLanguage) ? 'right: 7.5%' : 'left: 7.5%'};
    }
    &:after {
      display: none;
    }
  `};
`;

const itemPaddingNext = css<{ language?: SupportedLanguage }>`
  padding-right: ${({ language }) => (baseTheme.isRTL(language) ? '15%' : '0')};
  padding-left: ${({ language }) => (baseTheme.isRTL(language) ? '0' : '15%')};
`;

const itemPaddingAfter = css<{ language?: SupportedLanguage }>`
  padding-left: ${({ language }) => (baseTheme.isRTL(language) ? '15%' : '0')};
  padding-right: ${({ language }) => (baseTheme.isRTL(language) ? '0' : '15%')};
`;

const slideItemNextStyles = css`
  ${baseTheme.hilton.mqs.largeAndUp`
    ${itemPaddingNext}
    &:before {
      ${({ language }) =>
        baseTheme.isRTL(language as SupportedLanguage) ? 'right: 7.5%' : 'left: 7.5%'};
    }
    &:after {
      ${({ language }) =>
        baseTheme.isRTL(language as SupportedLanguage) ? 'left: -7.5%' : 'right: -7.5%'};
    }
  `};
`;

const slidePagerNextStyles = css`
  ${baseTheme.hilton.mqs.largeAndUp`
    ${({ language }) =>
      baseTheme.isRTL(language as SupportedLanguage) ? 'left: 42.5%' : 'left: 57.5%'};
  `};
`;

// Adds additional css for our use case depending on active slide
const rawStyles = css`
  .swiper-container {
    min-height: 460px;
  }
  .swiper-slide-prev .dx-item {
    ${inActiveLeftLine};
  }
  .swiper-slide-next .dx-item {
    ${inActiveLeftLine};
  }
  .swiper-slide .dx-item {
    ${slideItemStyles};
  }
  .swiper-slide-active .dx-item {
    ${slideItemActiveStyles};
  }
  .swiper-slide-active .dx-pager {
    background-color: #009cde;
    border-color: transparent;
    transform: translateX(-50%) scale(1.35);
    left: 50%;
  }
  .swiper-slide-active .dx-content {
    ${slideContentActiveStyles};
  }
  .swiper-slide-next .dx-item,
  .swiper-slide-next + .swiper-slide .dx-item {
    ${slideItemNextStyles};
  }
  .swiper-slide-next .dx-pager,
  .swiper-slide-next + .swiper-slide .dx-pager {
    ${slidePagerNextStyles};
  }
  .swiper-slide-next + .swiper-slide .dx-item {
    ${slideItemLastStyles};
  }
`;

// Inject raw styles above into the wrapper
const CarouselWrapper = styled.section<{ language?: SupportedLanguage }>`
  margin: 0 auto;
  min-height: 580px;
  ${breakpoints.tabletLandscapeAndUp} {
    width: 95%;
  }
  ${baseTheme.hilton.mqs.xtraLarge`
    min-height: 660px;
    width: 90%;
  `};
  ${rawStyles};
`;

const Wrapper = styled.section`
  background: url(${perks.desktop}) no-repeat center;
  background-size: cover;
  background-color: #132f5d;
  background-position-y: -19.5rem;
  ${baseTheme.hilton.mqs.largeAndDown`
    background-position: -10.75rem -9rem;
  `};
  ${baseTheme.hilton.mqs.smallAndDown`
    background-position: -15.75rem -9rem;
  `};
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.33);
  padding: 2rem 0 1rem;
  color: white;
  text-align: center;
  position: relative;
  z-index: 2;
  ${baseTheme.hilton.mqs.xtraLarge`
    padding: 4rem 0 2rem;
  `};

  &:after {
    box-shadow: 5px 5px 6px -2px rgba(0, 0, 0, 0.2);
    content: '';
    display: block;
    position: absolute;
    bottom: -27px;
    right: calc(50% - 28px);
    width: 54px;
    height: 54px;
    background: #092e5c;
    transform: rotate(45deg) skew(-10deg, -10deg);
  }
`;

const Header = styled.h2`
  font-size: 38px;
  margin-bottom: 0.25em;
  letter-spacing: 1px;
  ${breakpoints.mobile} {
    font-size: 30px;
  }
`;

const SubText = styled.h3`
  font-size: 24px;
  margin-bottom: 2em;
  letter-spacing: -0.3px;
  font-weight: normal;
  font-family: Proxima Nova;
  font-weight: 400;
  ${breakpoints.mobile} {
    font-size: 20px;
  }
`;

const Item = styled.section`
  text-align: center;
  color: #1d1d1d;
  display: flex;
  position: relative;
  padding: 0 7.5%;
  ${baseTheme.hilton.mqs.largeAndUp`
    ${itemPaddingAfter}
  `};
`;

const Content = styled.section`
  background-color: white;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.07);
  text-align: left;
  padding: 2em 1.4em;
  position: relative;
  box-sizing: border-box;
  margin-top: 110px;
  min-height: 200px;
  will-change: min-height;
  transition: background-color 400ms;
  ${baseTheme.hilton.mqs.smallAndDown`
    opacity: 0;
    position: absolute;
    padding: 3em 2em;
    width: 180%;
    left: 50%;
    transform: translateX(-50%);
    transition-delay: 100ms;
    transition: opacity 400ms;
  `};
  ${baseTheme.hilton.mqs.medium`
    margin-top: 140px;
    padding: 3em 2.5em;
    min-height: 295px;
  `};
  ${baseTheme.hilton.mqs.xtraLargeAndUp`
    min-height: 260px;
  `};
  ${baseTheme.hilton.mqs.xtraLarge`
    margin-top: 150px;
    min-height: 340px;
    padding: 3em;
  `};
  &:before {
    content: '';
    position: absolute;
    top: -20px;
    transition: 400ms;
    left: 50%;
    width: 0;
    height: 0;
    border-style: none;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-bottom: 20px solid white;
    transform: translateX(-50%);
  }
`;

const Pager = styled.section<{ language?: SupportedLanguage }>`
  background-color: #104c97;
  border-radius: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  margin: 0 auto;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.11);
  cursor: pointer;
  transition: background-color 400ms;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  border: 1px solid transparent;
  pointer-events: all;
  ${baseTheme.hilton.mqs.medium`
    width: 100px;
    height: 100px;
  `};
  ${baseTheme.hilton.mqs.xtraLarge`
    width: 110px;
    height: 110px;
  `};
  ${baseTheme.hilton.mqs.largeAndUp`
    ${({ language }) =>
      baseTheme.isRTL(language as SupportedLanguage) ? 'left: 57.5%' : 'left: 42.5%'};
    &:hover {
      border-color: rgba(255, 255, 255, 0.47);
      + ${Content} {
        background-color: #eaeaea;
        &:before {
          border-bottom-color: #eaeaea;
        }
      }
    }
  `};
`;

const ContentHeader = styled.p<{ outline?: boolean; language?: SupportedLanguage }>`
  font-weight: bold;
  letter-spacing: -0.3px;
  font-size: 22px;
  line-height: 1.25;
  margin-bottom: 0.7em;
  outline: ${(props) => (props.outline ? 'inherit' : 0)};
  ${baseTheme.hilton.mqs.medium`
    font-size: 28px;
  `};
  ${baseTheme.hilton.mqs.xtraLarge`
    font-size: 28px;
    margin-bottom: 1.2em;
  `};
  ${({ language }) => baseTheme.isRTL(language) && `text-align: right;`}
`;

const ContentDescription = styled.p<{ language?: SupportedLanguage }>`
  font-size: 14px;
  margin-bottom: 1em;
  position: relative;
  &:last-child {
    margin-bottom: 0;
  }
  ${baseTheme.hilton.mqs.xtraLarge`
    font-size: 16px;
  `};
  ${({ language }) => baseTheme.isRTL(language) && `text-align: right;`}
`;

const NavArrows = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
`;

type ArrowProps = Partial<{
  isLeft: boolean;
  language: SupportedLanguage;
}>;

const transformCss = ({ isLeft, language }: ArrowProps) => {
  if (isLeft) {
    if (baseTheme.isRTL(language)) {
      return null;
    }
    return 'rotate(180deg)';
  }
  if (baseTheme.isRTL(language)) {
    return 'rotate(180deg)';
  }
  return null;
};

const Arrow = styled.button<ArrowProps>`
  background-color: transparent;
  border: 0;
  padding: 0;
  color: white;
  display: inline-flex;
  justify-content: center;
  font-size: 32px;
  align-items: center;
  transform-origin: center 0.5em;
  transform: ${({ isLeft, language }) => transformCss({ isLeft, language })};
  svg {
    fill: currentColor;
    height: 1em;
    width: 1em;
  }
`;

const Text = styled.section`
  display: inline-block;
  margin: 0 30px;
  font-size: 14px;
`;

export {
  OuterWrapper,
  CarouselWrapper,
  Wrapper,
  Pager,
  Item,
  Content,
  ContentHeader,
  ContentDescription,
  Header,
  SubText,
  NavArrows,
  Arrow,
  Text,
  Li,
};
